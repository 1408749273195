import type { Commit, Dispatch } from "vuex";
import {
  CartItem,
  Coupon,
  ShopCategory,
  ShopField,
  ShopProduct,
  ShopProductVariant,
} from "@/types/shop";
import ShopService from "@/services/shop.service";
import GalleryService from "@/services/gallery.service";
import { GalleryImage } from "@/types/main";
import {
  getDiscount,
  getMinPrice,
  getProductItemPrice,
  getSortedByPriceVariants,
  getStandardProductItemPrice,
} from "@/helpers/shop.helper";

function formatCategories(
  categories: Array<ShopCategory>
): Array<ShopCategory> {
  const formattedCategories: Array<ShopCategory> = [];

  function processSubcategories(
    parentId: string,
    parentPath: string,
    parentIdsPath: string
  ): Array<ShopCategory> {
    const subcategories = categories.filter(
      (cat: ShopCategory) => cat.parent === parentId
    );
    if (subcategories.length === 0) return [];

    return [...subcategories]
      .map((cat: ShopCategory) => {
        return {
          ...cat,
          path: (parentPath ? parentPath + "/" : "") + cat.title,
          idsPath: (parentIdsPath ? parentIdsPath + "/" : "") + cat._id,
          subcategories: processSubcategories(
            cat._id,
            (parentPath ? parentPath + "/" : "") + cat.title,
            (parentIdsPath ? parentIdsPath + "/" : "") + cat._id
          ),
        };
      })
      .sort((a, b) => (a.order || 0) - (b.order || 0));
  }

  const mainCategories = categories
    .filter(
      (cat: ShopCategory) =>
        !cat.parent || !categories.some((c) => c._id === cat.parent)
    )
    .sort((a, b) => (a.order || 100000) - (b.order || 100000));

  mainCategories.forEach((cat: ShopCategory) => {
    formattedCategories.push({
      ...cat,
      subcategories: processSubcategories(
        cat._id,
        cat.title !== "root" ? cat.title : "",
        cat.title !== "root" ? cat._id : ""
      ),
      path: cat.title !== "root" ? cat.title : "",
      idsPath: cat.title !== "root" ? cat._id : "",
    });
  });
  if (formattedCategories.length > 1) {
    formattedCategories[0].subcategories = [
      ...(formattedCategories[0].subcategories || []),
      ...formattedCategories
        .filter((c, i) => i !== 0)
        .map((c) => ({ ...c, parent: formattedCategories[0]._id })),
    ];
  }

  return formattedCategories;
}

function flattenCategories(
  categories: Array<ShopCategory>
): Array<ShopCategory> {
  const flattenedCategories: Array<ShopCategory> = [];

  function processSubcategories(subcategories: Array<ShopCategory>) {
    subcategories.forEach((cat) => {
      flattenedCategories.push(cat);
      if (cat.subcategories && cat.subcategories.length > 0) {
        processSubcategories(cat.subcategories);
      }
    });
  }

  categories?.forEach((cat: ShopCategory) => {
    flattenedCategories.push(cat);
    if (cat.subcategories && cat.subcategories.length > 0) {
      processSubcategories(cat.subcategories);
    }
  });

  return flattenedCategories;
}

export enum ShopActions {
  FETCH_STATS = "fetchStats",
  FETCH_SHOP_GALLERY = "fetchShopGallery",
  DELETE_IMAGE = "deleteImage",
  UPLOAD_IMAGE = "uploadImage",
  FETCH_COUPONS = "fetchCoupons",
  FETCH_SHOP_PRODUCTS = "fetchShopProducts",
  FETCH_SHOP_PRODUCTS_BASIC = "fetchShopProductsBasic",
  FETCH_SHOP_CATEGORIES = "fetchShopCategories",
  FETCH_SHOP_FIELDS = "fetchShopFields",
  UPDATE_SHOP_CATEGORY = "updateShopCategory",
  UPDATE_COUPON = "updateCoupon",
  UPDATE_SHOP_PRODUCT = "updateShopProduct",
  UPDATE_SHOP_FIELD = "updateShopField",
  UPDATE_SHOP_CATEGORIES = "updateShopCategories",
  DELETE_SHOP_CATEGORY = "deleteShopCategory",
  DELETE_COUPON = "deleteCoupon",
  DELETE_SHOP_PRODUCT = "deleteShopProduct",
  DELETE_SHOP_FIELD = "deleteShopField",
  ADD_SHOP_CATEGORY = "addShopCategory",
  ADD_COUPON = "addCoupon",
  ADD_SHOP_FIELD = "addShopField",
  ADD_SHOP_PRODUCT = "addShopProduct",
}

export enum ShopMutations {
  ADD_FOLDER = "addFolder",
  SET_STATS = "setStats",
  SET_COUPONS = "setCoupons",
  SET_SHOP_GALLERY = "setShopGallery",
  SET_SHOP_PRODUCTS = "setShopProducts",
  SET_SHOP_PRODUCTS_BASIC = "setShopProductsBasic",
  SET_IMAGES_IS_LOADING = "setImagesIsLoading",
  SET_PRODUCTS_IS_LOADING = "setProductsIsLoading",
  SET_CATEGORIES_IS_LOADING = "setCategoriesIsLoading",
  SET_SHOP_CATEGORIES = "setShopCategories",
  SET_SHOP_FIELDS = "setShopFields",
  SET_SHOP_PRODUCT = "setShopProduct",
  ADD_SHOP_CATEGORY = "addShopCategory",
  ADD_SHOP_FIELD = "addShopField",
  UPDATE_SHOP_CATEGORY = "updateShopCategory",
  UPDATE_SHOP_PRODUCT = "updateShopProduct",
  UPDATE_SHOP_FIELD = "updateShopField",
}

export interface ShopState {
  baseFolders: Array<string>;
  stats: { [key: string]: number } | undefined;
  coupons: Array<Coupon>;
  shopGallery: Array<GalleryImage>;
  shopProducts: Array<ShopProduct>;
  shopProductsBasic: Array<ShopProduct>;
  productsIsLoading: boolean;
  imagesIsLoading: boolean;
  categoriesIsLoading: boolean;
  shopCategories: Array<ShopCategory>;
  shopFields: Array<ShopField>;
  categoryBanners: Array<{ title: string; img: string; to: string }>;
}

export const state = (): ShopState => ({
  baseFolders: [],
  stats: undefined,
  imagesIsLoading: false,
  productsIsLoading: false,
  categoriesIsLoading: false,
  coupons: [],
  shopGallery: [],
  categoryBanners: [
    {
      title: "Wyprawki",
      img: "/images/wyprawki.png",
      to: "/produkty?wyprawki=tak",
    },
    {
      title: "Wyprzedaż",
      img: "/images/wyprzedaz2.png",
      to: "/produkty?wyprzedaz=tak",
    },
    {
      title: "Zestawy prezentowe",
      img: "/images/zestawy.png",
      to: "/produkty?zestawy=tak",
    },
  ],
  shopCategories: [],
  shopFields: [],
  shopProducts: [],
  shopProductsBasic: [],
});

export const getters = {
  galleryFolders: (state: ShopState) => {
    const folders = Array.from(
      new Set([...state.baseFolders, ...state.shopGallery.map((i) => i.folder)])
    ).filter((f) => f?.length);
    const nestedFolders = folders.filter((f) => f?.includes("/"));
    nestedFolders.forEach((folder) => {
      if (folder) {
        const splitted = folder.split("/");
        let path = "";
        splitted.forEach((item: string) => {
          path = path + (path ? "/" : "") + item;
          if (!folders.includes(path)) folders.push(path);
        });
      }
    });
    return folders.sort((a, b) =>
      (a || "")
        ?.toLowerCase()
        ?.trim()
        .localeCompare((b || "")?.toLowerCase()?.trim())
    );
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  visibleShopProducts: (state: ShopState, getters) =>
    getters.shopProducts.filter((p: ShopProduct) => !p.isHidden),
  shopGallery: (state: ShopState) => state.shopGallery,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  shopProducts: (state: ShopState, getters) =>
    [...state.shopProducts].map((p) => {
      const sortedVariants = getSortedByPriceVariants(p);
      const selectedVariant = sortedVariants.length
        ? sortedVariants[0]
        : undefined;
      const price = getProductItemPrice(p, selectedVariant);
      const standardPrice = getStandardProductItemPrice(p, selectedVariant);
      const minPrice = getMinPrice(p, selectedVariant);
      const discount = getDiscount(price, standardPrice);
      const categoriesId = p.categories.map(
        (cat) =>
          getters.flattenShopCategories.find((c: ShopCategory) => c._id === cat)
            ?.idsPath
      );
      const totalQuantity = p.variants?.length
        ? [...p.variants].reduce((total: number, item: ShopProductVariant) => {
            return total + (item.quantity ? item.quantity : p.quantity);
          }, 0)
        : p.quantity;
      return {
        ...p,
        thumbnail: {
          totalQuantity,
          price,
          standardPrice,
          minPrice,
          discount,
          categoriesId,
        },
      };
    }),
  productsIsLoading: (state: ShopState) => state.productsIsLoading,
  categoriesIsLoading: (state: ShopState) => state.categoriesIsLoading,
  shopFields: (state: ShopState) => state.shopFields,
  shopProductsBasic: (state: ShopState) => state.shopProductsBasic,
  coupons: (state: ShopState) => state.coupons,
  stats: (state: ShopState) => state.stats,
  allShopCategories: (state: ShopState) =>
    formatCategories(state.shopCategories),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  shopCategories: (state: ShopState, getters) =>
    getters.rootShopCategories?.subcategories,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  flattenShopCategories: (state: ShopState, getters) =>
    flattenCategories(getters.shopCategories),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  rootShopCategories: (state: ShopState, getters) =>
    getters.allShopCategories.find((c: ShopCategory) => c.title === "root"),
};

export const actions = {
  async [ShopActions.DELETE_IMAGE](
    { dispatch, commit }: { dispatch: Dispatch; commit: Commit },
    payload: GalleryImage
  ) {
    try {
      commit(ShopMutations.SET_IMAGES_IS_LOADING, true);
      await GalleryService.deleteImage(payload);
      await dispatch(ShopActions.FETCH_SHOP_GALLERY);
    } finally {
      commit(ShopMutations.SET_IMAGES_IS_LOADING, false);
    }
  },
  async [ShopActions.UPLOAD_IMAGE](
    { dispatch, commit }: { dispatch: Dispatch; commit: Commit },
    payload: FormData
  ) {
    try {
      commit(ShopMutations.SET_IMAGES_IS_LOADING, true);
      await GalleryService.uploadFile(payload);
      await dispatch(ShopActions.FETCH_SHOP_GALLERY);
    } finally {
      commit(ShopMutations.SET_IMAGES_IS_LOADING, false);
    }
  },
  async [ShopActions.FETCH_STATS]({ commit }: { commit: Commit }) {
    const response = await ShopService.getShopStats();
    commit(ShopMutations.SET_STATS, response.stats);
  },
  async [ShopActions.FETCH_SHOP_CATEGORIES]({ commit }: { commit: Commit }) {
    try {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, true);
      const response = await ShopService.getShopCategories();
      commit(ShopMutations.SET_SHOP_CATEGORIES, response.categories);
    } finally {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, false);
    }
  },
  async [ShopActions.FETCH_SHOP_PRODUCTS]({ commit }: { commit: Commit }) {
    try {
      commit(ShopMutations.SET_PRODUCTS_IS_LOADING, true);
      const response = await ShopService.getShopProducts();
      commit(ShopMutations.SET_SHOP_PRODUCTS, response.products);
    } finally {
      commit(ShopMutations.SET_PRODUCTS_IS_LOADING, false);
    }
  },
  async [ShopActions.FETCH_SHOP_PRODUCTS_BASIC]({
    commit,
  }: {
    commit: Commit;
  }) {
    const response = await ShopService.getShopProductsBasic();
    commit(ShopMutations.SET_SHOP_PRODUCTS_BASIC, response.products);
  },
  async [ShopActions.FETCH_SHOP_GALLERY]({ commit }: { commit: Commit }) {
    try {
      commit(ShopMutations.SET_IMAGES_IS_LOADING, true);
      const response = await GalleryService.getGallery();
      commit(ShopMutations.SET_SHOP_GALLERY, response.images);
    } finally {
      commit(ShopMutations.SET_IMAGES_IS_LOADING, false);
    }
  },
  async [ShopActions.FETCH_COUPONS]({ commit }: { commit: Commit }) {
    const response = await ShopService.getCoupons();
    commit(ShopMutations.SET_COUPONS, response.coupons);
  },
  async [ShopActions.FETCH_SHOP_FIELDS]({ commit }: { commit: Commit }) {
    const response = await ShopService.getShopFields();
    commit(ShopMutations.SET_SHOP_FIELDS, response.fields);
  },
  async [ShopActions.ADD_SHOP_CATEGORY](
    { dispatch, commit }: { dispatch: Dispatch; commit: Commit },
    payload: ShopCategory
  ) {
    try {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, true);
      const res = await ShopService.addShopCategory(payload);
      commit(ShopMutations.SET_SHOP_CATEGORIES, res.categories);
    } finally {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, false);
    }
  },
  async [ShopActions.ADD_COUPON](
    { dispatch }: { dispatch: Dispatch },
    payload: Coupon
  ) {
    await ShopService.addCoupon(payload);
    await dispatch(ShopActions.FETCH_COUPONS);
  },
  async [ShopActions.ADD_SHOP_FIELD](
    { dispatch }: { dispatch: Dispatch },
    payload: ShopField
  ) {
    await ShopService.addShopField(payload);
    await dispatch(ShopActions.FETCH_SHOP_FIELDS);
  },
  async [ShopActions.ADD_SHOP_PRODUCT](
    { dispatch }: { dispatch: Dispatch },
    payload: ShopProduct
  ) {
    await ShopService.addShopProduct(payload);
    await dispatch(ShopActions.FETCH_SHOP_PRODUCTS);
  },
  async [ShopActions.UPDATE_SHOP_CATEGORY](
    { dispatch, commit }: { dispatch: Dispatch; commit: Commit },
    payload: ShopCategory
  ) {
    try {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, true);
      const res = await ShopService.updateShopCategory(payload);
      commit(ShopMutations.SET_SHOP_CATEGORIES, res.categories);
    } finally {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, false);
    }
  },
  async [ShopActions.UPDATE_COUPON](
    { dispatch }: { dispatch: Dispatch },
    payload: Coupon
  ) {
    await ShopService.updateCoupon(payload);
    await dispatch(ShopActions.FETCH_COUPONS);
  },
  async [ShopActions.UPDATE_SHOP_PRODUCT](
    { dispatch }: { dispatch: Dispatch },
    payload: ShopProduct
  ) {
    await ShopService.updateShopProduct(payload);
    await dispatch(ShopActions.FETCH_SHOP_PRODUCTS);
  },
  async [ShopActions.UPDATE_SHOP_FIELD](
    { commit }: { commit: Commit },
    payload: ShopField
  ) {
    const res = await ShopService.updateShopField(payload);
    commit(ShopMutations.UPDATE_SHOP_FIELD, res.field);
  },
  async [ShopActions.UPDATE_SHOP_CATEGORIES](
    { dispatch, commit }: { dispatch: Dispatch; commit: Commit },
    payload: Array<ShopCategory>
  ) {
    try {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, true);
      const res = await ShopService.updateShopCategories(payload);
      commit(ShopMutations.SET_SHOP_CATEGORIES, res.categories);
    } finally {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, false);
    }
  },
  async [ShopActions.DELETE_SHOP_CATEGORY](
    { dispatch, commit }: { dispatch: Dispatch; commit: Commit },
    payload: ShopCategory
  ) {
    try {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, true);
      const res = await ShopService.deleteShopCategory(payload);
      commit(ShopMutations.SET_SHOP_CATEGORIES, res.categories);
    } finally {
      commit(ShopMutations.SET_CATEGORIES_IS_LOADING, false);
    }
  },
  async [ShopActions.DELETE_COUPON](
    { dispatch }: { dispatch: Dispatch },
    payload: Coupon
  ) {
    await ShopService.deleteCoupon(payload);
    await dispatch(ShopActions.FETCH_COUPONS);
  },
  async [ShopActions.DELETE_SHOP_PRODUCT](
    { dispatch }: { dispatch: Dispatch },
    payload: ShopProduct
  ) {
    await ShopService.deleteShopProduct(payload);
    await dispatch(ShopActions.FETCH_SHOP_PRODUCTS);
  },
  async [ShopActions.DELETE_SHOP_FIELD](
    { dispatch }: { dispatch: Dispatch },
    payload: ShopField
  ) {
    await ShopService.deleteShopField(payload);
    await dispatch(ShopActions.FETCH_SHOP_FIELDS);
  },
};

export const mutations = {
  [ShopMutations.ADD_FOLDER](state: ShopState, payload: string) {
    state.baseFolders.push(payload);
  },
  [ShopMutations.SET_SHOP_FIELDS](state: ShopState, payload: Array<ShopField>) {
    state.shopFields = payload;
  },
  [ShopMutations.SET_STATS](
    state: ShopState,
    payload: { [key: string]: number }
  ) {
    state.stats = payload;
  },
  [ShopMutations.SET_SHOP_CATEGORIES](
    state: ShopState,
    payload: Array<ShopCategory>
  ) {
    state.shopCategories = payload;
  },
  [ShopMutations.SET_SHOP_PRODUCTS](
    state: ShopState,
    payload: Array<ShopProduct>
  ) {
    state.shopProducts = payload;
  },
  [ShopMutations.SET_SHOP_PRODUCTS_BASIC](
    state: ShopState,
    payload: Array<ShopProduct>
  ) {
    state.shopProductsBasic = payload;
  },
  [ShopMutations.SET_PRODUCTS_IS_LOADING](state: ShopState, payload: boolean) {
    state.productsIsLoading = payload;
  },
  [ShopMutations.SET_IMAGES_IS_LOADING](state: ShopState, payload: boolean) {
    state.imagesIsLoading = payload;
  },
  [ShopMutations.SET_CATEGORIES_IS_LOADING](
    state: ShopState,
    payload: boolean
  ) {
    state.categoriesIsLoading = payload;
  },
  [ShopMutations.SET_SHOP_GALLERY](
    state: ShopState,
    payload: Array<GalleryImage>
  ) {
    state.shopGallery = [...payload];
  },
  [ShopMutations.SET_COUPONS](state: ShopState, payload: Array<Coupon>) {
    state.coupons = payload;
  },
  [ShopMutations.ADD_SHOP_CATEGORY](state: ShopState, payload: ShopCategory) {
    state.shopCategories.push(payload);
  },
  [ShopMutations.UPDATE_SHOP_CATEGORY](
    state: ShopState,
    payload: ShopCategory
  ) {
    const category = state.shopCategories.find(
      (cat) => cat._id === payload._id
    );
    if (category) {
      category.title = payload.title;
      category.parent = payload.parent;
    }
  },
  [ShopMutations.UPDATE_SHOP_FIELD](state: ShopState, payload: ShopField) {
    const field = state.shopFields.find((f) => f._id === payload._id);
    if (field) {
      field.title = payload.title;
      field.options = payload.options;
      field.type = payload.type;
      field.description = payload.description;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
